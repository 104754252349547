import React from 'react';

const Header = () => (
  <div>

    <div className="flex justify-between align-center px-10 mt-5 text-blue-900">
      {/* https://ik.imagekit.io/pamphilemkpchill/Australia_240-animated-flag-gifs.gif?updatedAt=1716902871643 */}

      <img src="https://ik.imagekit.io/pamphilemkpchill/Democratic-Republic-of-the-Congo_240-animated-flag-gifs.gif?updatedAt=1691674544560" alt="Rdc Flag" className="w-[10vw]  h-[10vh] cursor-pointer" />
      <img src="https://ik.imagekit.io/pamphilemkpchill/Australia_240-animated-flag-gifs.gif?updatedAt=1716902871643" alt="Australian Flag" className="w-[10vw]  h-[10vh] cursor-pointer" />
      {/* <img src="https://ik.imagekit.io/pamphilemkpchill/Britanny_240-animated-flag-gifs.gif?updatedAt=1692027780482" alt="Breton flag" className="w-[10vw]  h-[10vh] cursor-pointer"  /> */}
    </div>
  </div>
);

export default Header;
