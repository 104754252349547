/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import OKAPIRARE from '../images/okapirare.jpg';

const navigation = [
  { name: 'Acueil', path: '/', current: false },
  { name: 'A propos', path: '/Apropos', current: false },
  { name: 'Provinces', path: '/RdcTerritoires', current: false },
  { name: 'Programmes', path: '/Programme', current: false },
  { name: 'Enregistrements', path: '/Enregistrement', current: false },
  { name: 'Speakers', path: '/Speakers', current: false },
  { name: 'News', path: '/News', current: false },
  { name: 'Gallery', path: '/Gallery', current: false },
  { name: 'Contact', path: '/Contact', current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const NavBar = () => {
  const activeLink = 'bg-gray-900 hover:bg-gray-100 text-white hover:text-gray-800 block rounded-md px-3 py-2 text-base font-medium';
  const notActive = 'rounded-md px-3 py-2 text-md font-medium text-gray-800 hover:bg-gray-100 hover:text-gary-800';

  return (
    <>
      <Disclosure as="nav" className="text-gray-900 py-5">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl  px-4 sm:px-6 ">
              <div className="relative flex h-16 items-center justify-between">
                <div className="flex items-center">
                  <div className="self-start flex md:1/3">
                    <div className="flex pb-2.5 justify-between items-center py-2.5">
                      <img
                        className="h-20 w-35 mr-4 md:mr-8 lg:mr-4  rounded-full"
                        src={OKAPIRARE}
                        alt="Logo Okapy rare en RDC."
                      />
                      <h1 className="h-8 w-60 text-white text-[8px] md:hidden">
                        CONFERENCE INTERNATIONALE SUR LE DEVELOPPEMENT DES 145 TERRITOIRES
                        DE LA REPUBLIQUE DEMOCRATIQUE DU CONGO, RDC.
                      </h1>
                    </div>
                  </div>
                  <div className="hidden lg:block ">
                    <div className="ml-10 md:ml-10 flex items-baseline space-x-4 truncate">
                      { navigation.map(
                        (item) => (
                          <NavLink
                            key={item.name}
                            to={item.path}
                            className={({ isActive }) => (isActive ? activeLink : notActive)}
                            aria-current={item.current ? 'page' : undefined}
                          >
                            {item.name}
                          </NavLink>
                        ),
                      )}
                    </div>
                  </div>
                </div>

                <div className="mr-2 flex lg:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="bg-gray-800 md:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                {navigation.map((item) => (
                  <NavLink
                    key={item.name}
                    as="a"
                    to={item.path}
                    className={classNames(
                      item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'block rounded-md px-3 py-2 text-base font-medium',
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </NavLink>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
};

export default NavBar;
