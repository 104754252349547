/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import React from 'react';
import {
  faPhone,
  faBullhorn,
  faBedPulse,
  faAward,
  faPersonCircleQuestion,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import NavBar from '../components/NavBar';
import CountDown from '../components/CountDown';
import awards from '../images/cards/awards.jpg';
import contact from '../images/cards/contact.jpg';
import events from '../images/cards/events.jpg';
import faqs from '../images/cards/faqs.jpg';
import hotels from '../images/cards/hotels.jpg';
import news from '../images/cards/news.jpg';
import SponsorsLogo from '../components/SponsorsLogo';
import SeeMoreButton from '../components/SeeMoreRennes';
import ShowMoreButton from '../components/SeeMoreAbout';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../components/Header';

const Acueil = () => (
  <div>
    <Header />
    <NavBar />
    <div className="flex flex-col mx-auto border-t-2 border-gray-800 text-center md:mx-auto md:text-center  md:justify-center md:flex-row align-center bg-orange-500 justify-center px-10 py-3 text-white my-1">
      <h2 className="w-full md:w-1/2 h- flex flex-col justify-center  text-lg md:text-sm">
        Compte à rebours de la #CID 145 TERRITOIRES edition 2.
        <span className="hidden md:inline">
          République Démocratique du Congo, RDC
        </span>
      </h2>
      <CountDown />

    </div>

    <div
      className="bg-gray-800  h-[80vh] bg-cover bg-center text-white flex justify-center align-center text-center py-10"
      style={{
        backgroundImage: 'url("https://ik.imagekit.io/pamphilemkpchill/forestHigh.gif?updatedAt=1688374530126")',
        backgroundSize: 'cover',
      }}
    >
      <div className=" flex flex-col align-center justify-center gap-1  md:justify-between px-3 sm:px-10 py-10 md:px-5">
        <div className="flex md:justify-center">
          <span className="text-bold text-4xl md:text-5xl">
            Melbourne, Australie du 13 au 15 Mars 2025
          </span>
        </div>
        <div className="flex  md:justify-center">
          <span className="text-bold text-yellow-500 text-2xl md:text-3xl">
            RDC, EKOLO MAKASI
          </span>
        </div>

        <div className="flex justify-center align-center text-center text-lg md:text-2xl">
          <span className="md:w-3/4 text-justify">
            Rôle des femmes, filles et jeunes dans le développement local en RDC
          </span>
        </div>
        <div className="flex justify-center align-center sm:text-center">
          <p className="md:w-3/4 lowercase text-sm text-bold text-justify">
            {` La combinaison de deux mots lingala, EKOLO, qui signifie pays et
            MAKASI, fort, ça veut dire un pays fort. Il s’agit d’une
            révolution qui met ensemble les Congolais de la diaspora et ceux
            du pays pour développer leur pays à travers l’entrepreneuriat et
            le tiers secteur.`}
          </p>
        </div>
      </div>
    </div>

    <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mx-3  md:mx-5 lg:mx-20 gap-10 md:gap-10 lg:gap-3 mt-20 px-5 py-5 text-black">
      {/* cards */}
      <Link
        to="/SpecialEvents"
        className="bg-white bg-cover bg-center flex flex-col justify-center align-center text-center h-[300px] gap-5"
        style={{
          backgroundImage: `url(${events})`,
          backgroundSize: 'cover',
        }}
      >
        <div className="text-white bg-gray-800 bg-opacity-10 flex flex-col justify-center align-center text-center backdrop-filter backdrop-blur-lg bg-blend-darken py-5">
          <FontAwesomeIcon
            icon={faUsers}
            className="w-[50px] h-[50px] text-center self-center"
          />
          <span className="text-bold text-lg">Special Evenements</span>
        </div>
      </Link>

      <Link
        to="/Awards"
        className="bg-black-800 bg-cover bg-center flex flex-col justify-center align-center text-center h-[300px] gap-5"
        style={{ backgroundImage: `url(${awards})`, backgroundSize: 'cover' }}
      >
        <div className="text-white bg-gray-800 bg-opacity-30 flex flex-col justify-center align-center text-center backdrop-filter backdrop-blur-lg bg-blend-darken py-5">
          <FontAwesomeIcon
            icon={faAward}
            className="w-[50px] h-[50px] text-center self-center "
          />
          <span className="text-bold text-lg">Awards</span>
        </div>
      </Link>

      <Link
        to="/Hotels"
        className="bg-black-800 bg-cover bg-center flex flex-col justify-center align-center text-center h-[300px] gap-5"
        style={{ backgroundImage: `url(${hotels})`, backgroundSize: 'cover' }}
      >
        <div className="text-white bg-gray-800 bg-opacity-30 flex flex-col justify-center align-center text-center backdrop-filter backdrop-blur-lg bg-blend-darken py-5">
          <FontAwesomeIcon
            icon={faBedPulse}
            className="w-[50px] h-[50px] text-center self-center "
          />
          <span className="text-bold text-lg">Hotels</span>
        </div>
      </Link>

      <Link
        to="/Faqs"
        className="bg-black-800 bg-cover bg-center flex flex-col justify-center align-center text-center h-[300px] gap-5"
        style={{ backgroundImage: `url(${faqs})`, backgroundSize: 'cover' }}
      >
        <div className="text-white bg-gray-800 bg-opacity-30 flex flex-col justify-center align-center text-center backdrop-filter backdrop-blur-lg bg-blend-darken py-5">
          <FontAwesomeIcon
            icon={faPersonCircleQuestion}
            className="w-[50px] h-[50px] text-center self-center "
          />
          <span className="text-bold text-lg">FaQs</span>
        </div>
      </Link>

      <Link
        to="/News"
        className="bg-black-800 bg-cover bg-center flex flex-col justify-center align-center text-center h-[300px] gap-5"
        style={{ backgroundImage: `url(${news})`, backgroundSize: 'cover' }}
      >
        <div className="text-white bg-gray-800 bg-opacity-10 flex flex-col justify-center align-center text-center backdrop-filter backdrop-blur-lg bg-blend-darken py-5">
          <FontAwesomeIcon
            icon={faBullhorn}
            className="w-[50px] h-[50px] text-center self-center "
          />
          <span className="text-bold text-lg">News</span>
        </div>
      </Link>

      <Link
        to="/Contact"
        className="bg-black-800 bg-cover bg-center flex flex-col justify-center align-center text-center h-[300px] gap-5"
        style={{
          backgroundImage: `url(${contact})`,
          backgroundSize: 'cover',
        }}
      >
        <div className="text-white bg-gray-800 bg-opacity-50 flex flex-col justify-center align-center text-center backdrop-filter backdrop-blur-lg bg-blend-darken py-5">
          <FontAwesomeIcon
            icon={faPhone}
            className="w-[50px] h-[50px] text-center self-center "
          />
          <span className="text-bold text-lg">Contact</span>
        </div>
      </Link>
    </div>

    <div className="bg-sky-900 text-white mt-5 mb-10 mx-3 rounded">
      <div className="text-white flex flex-col justify-center align-center sm:px-5 mx-3">
        <p className="text-4xl  text-bold text-center lg:text-left  lg:mx-20  my-5 text-yellow-500 ">
          A propos
        </p>
        <ShowMoreButton />
      </div>
    </div>

    <div className="bg-sky-900 text-white mt-5 mb-10 mx-3 rounded ">
      <div className=" flex flex-col justify-center align-center sm:px-5 mx-3">
        <p className="text-4xl text-bold text-center lg:text-left  lg:mx-20  my-5 text-yellow-500">
          Le choix de Rennes
        </p>
        <SeeMoreButton />
      </div>
    </div>

    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-semibold mb-4">Pour le budget participatif</h1>

      {/* <!-- YouTube Video Embed Code --> */}
      <div className="w-full aspect-w-16 aspect-h-9">
        {/* <iframe class="w-full h-full" src="https://www.youtube.com/embed/YOUR_VIDEO_ID" frameborder="0" allowfullscreen></iframe> */}
        <iframe
          height="480"
          src="https://www.youtube.com/embed/gw35Vov64MU"
          className="w-full  aspect-w-16 aspect-h-9"
          title="POLITIQUE PUBLIQUE INCLUSIVE: LE BUDGET PARTICIPATIF"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        />
      </div>
    </div>

    <SponsorsLogo />

    <div className="bg-gray-800 flex pt-10 pl-3 sm:pl-10 pb-3 mt-4 text-sm sm:text-md border-t border-gray-500 text-bold">

      <p className="flex text-white">
        ©2024 RDC, 145 TERRITOIRES. All rights reserved.
        {' '}
        <a href="https://github.com/Pamphilemkp" className="text-white">
          {' '}
          by Pamphile MKP
        </a>
      </p>
    </div>
    <ToastContainer />
  </div>
);

export default Acueil;
