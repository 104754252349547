/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable quotes */
/* eslint-disable react/button-has-type */
import React, { useState } from 'react';

const ShowMoreButton = () => {
  const [showMore, setShowMore] = useState(false);

  const handleToggle = () => {
    setShowMore(!showMore);
  };

  return (
    <div>
      <div className="flex flex-col gap-3 text-jusify text-md lg:mx-20 lg:text-md pt-5">
        {/* Your initial content here */}
        <p>
          {` Trois associations françaises et une australienne ont conclu une convention de partenariat pour rechercher,
          capter et canaliser des investissements directs en faveur des 145 territoires de la République Démocratique
          du Congo. Il s’agit de l’Union des Associations Interculturelle de Rennes, UAIR, les Artisans d’Ile et
          de Vilaine et Nyota du côté français, Applx du côté Australien. .`}
          {' '}
        </p>

        <p>
          {` Par ailleurs, plusieurs associations de la Diaspora congolaise et des amis du Congo vont également entrer
          dans ce partenariat. Objectif, l’organisation d’une conférence internationale à Rennes, le 28 octobre 2023,
          sur le développement local des 145 territoires autour du concept “EKOLO MAKASI”.`}
        </p>
      </div>
      {showMore && (
        <div className="flex flex-col gap-3 text-jusify text-md lg:mx-20 lg:text-md">
          {/* Additional content here */}
          <p>
            {` Ces deux mots, « EKOLO MAKASI » sont tirés du Lingala, l’une des quatre langues principales de la RDC.
            « Ekolo » signifie Nation et « Makasi » signifie Forte. Cela veut dire un « pays fort ». Il s’agit des
            congolais de la diaspora qui se réunissent avec les nationaux pour développer les 145 territoires du
            pays en complémentarité avec l’Etat, à travers l’entrepreneuriat et le tiers-secteur.`}
          </p>

          <p>
            {` La conférence de Rennes va mettre ensemble la Diaspora, les amis du Congo, les acteurs venus du pays
            et les organismes internationaux, le 28 octobre 2023 autour des solutions d’investissements directs
            dans les 145 territoires.`}
          </p>

          <p>
            {` La particularité de ce projet est d’amener les ressortissants congolais à investir au niveau local
            du territoire et non dans les grandes villes pour créer des effets multiplicateurs du bas vers le haut.
            Parmi nos objectifs les plus urgents il y a le développement de l’agro-industrie, la pêche, le tourisme,
            la formation, mais aussi la création du fonds d’investissement direct.`}
          </p>

          <p>
            {` En résumé, le projet consiste à capter et orienter des investissements directs de la diaspora dans
            le développement local des 145 territoires en RD Congo, la participation effective des citoyens en
            complémentarité des efforts de l’Etat.`}
          </p>

          <ol>
            La situation socio-économique actuelle du pays
            est caractérisée par deux phénomènes négatifs
            qu’il faut changer, à savoir:
            <li>{

            `1- L’extravertisme économique basé sur la focalisation des efforts 
            sur le secteur minier au détriment d’une économie diversifiée `
}
            </li>
            <li>2- L’importationisme alimentaire ayant entrainé
              une dépendance chronique aux pays voisins,
              la pénurie de nourriture, la malnutrition …
            </li>
          </ol>

          <p>
            {' '}
            {`En effet, la RDC s’est enlisée dans un modèle économique colonial en dépit du fait que nous sommes politiquement indépendants depuis 63 ans.
            L’économie du pays est restée focalisée sur le secteur minier qui profite exclusivement à l’extérieur. Tous les minerais sont exportés à l’état
            vers les pays industrialisés qui, d’ailleurs, en fixent les prix. Il n’y a aucune industrie de transformation sur le sol congolais.`}
            {' '}
          </p>

          <p>
            {`La quasi-totalité des nourritures sont importées. Idem pour d’autres produits de première nécessité, par exemple, pour la construction, l’habillement,
            les banques, la téléphonie mobile etc. Dans la conception globale, les provinces ont été faussement classées en provinces riches ou pauvres selon
            qu’elles disposent des minerais ou non. Une grosse erreur car toutes les provinces sont riches de diverses ressources non mises en valeur.`}
          </p>

          <h4>Un paradoxe</h4>
          <p>
            {`  Le Congo dispose de 80 millions de terres arables, des lacs les plus poissonneux, d’un fleuve navigable et des forêts vierges. Selon la Cour
            des comptes du pays, 95% des personnes au sein de la population n’ont pas d’emploi formel.`}
          </p>

          <p>
            {`   Pour essayer de changer cette situation, le gouvernement a initié un programme de développement local des 145 territoires du pays. L’Etat va
            doter ces territoires des bâtiments administratifs et d’autres infrastructures telles que les écoles, les hôpitaux et les routes.`}
          </p>

          <ol>
            {` 
            C’est au travers de ce programme que j’aimerais amener
             mes compatriotes de la diaspora d’investir à travers deux secteurs,`}
            à savoir:
            <li>1-l’entrepreneuriat </li>
            <li>2-le tiers secteur.</li>
          </ol>

          <p>
            {`Certes, la diaspora investit déjà au pays mais ce sont des interventions isolées orientées vers les grandes villes. L’idée
            ici est de changer le mode d’investissement en structurant des investissements et en les orientant vers les territoires
            d’origine des investisseurs potentiels, aussi petits soient-ils.`}
          </p>

          <ol>
            Nous avons identifié quatre domaines prioritaires et propices, à savoir:
            <li>1-L’agro-industrie</li>
            <li>2-La pêche </li>
            <li>3-Le tourisme</li>
            <li> 4-La formation</li>
          </ol>

          <p>
            {`
            L’objectif est d’amener les gens soit, à se regrouper,
             soit à le faire seul, selon le cas mais en investissant au plus bas niveau`}
            de l’échelle communautaire.
          </p>

          <p>
            {`Nous sommes en discussions avec l’Etat qui est d’accord
             sur le principe d’accorder des facilites, à savoir:`}
          </p>

          <ol>
            <li>1-L’acces au foncier </li>
            <li>2-Des exonérations douanières et fiscales </li>
            <li>3-Des facilités administratives et procédurales </li>
          </ol>

          <p>
            {` Pour donner corps à tout ceci, nous avons conclu un accord de partenariat entre trois associations françaises
            bretonnes et une australienne pour l’organisation d’une conférence sur ce projet. L'événement aura lieu à Rennes
            au mois de septembre. Il réunira la diaspora, les amis de la RDC (des personnes autres que les congolais mais
            qui aiment le pays), les acteurs privés et étatiques venus du pays, les organismes internationaux.`}
          </p>

        </div>
      )}
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold my-5 py-2 px-4 rounded lg:mx-20"
        onClick={handleToggle}
      >
        {showMore ? 'Voir Moins' : 'Voir Plus'}
      </button>
    </div>
  );
};

export default ShowMoreButton;
